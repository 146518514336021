<template>
  <div class="home">
    <div class="swiper-container bannerHome">
      <div class="swiper-wrapper">
        <div class="swiper-slide">
          <img class="img_c1" src="../assets/img/banner_xxesd.png" alt="">
        </div>
        <!-- <div class="swiper-slide">
          <img class="img_c1" src="../assets/img/banner_img_d0005.png" alt="">
        </div> -->
        <div class="swiper-slide">
          <img class="img_c1" src="../assets/img/banner_img_0006.jpg" alt="">
        </div>
        <div class="swiper-slide">
          <img class="img_c1" src="../assets/img/banner_img_0008.jpg" alt="">
        </div>
        <div class="swiper-slide">
          <img class="img_c1" src="../assets/img/banner_img_0007.jpg" alt="">
        </div>
      </div>
      <div class="swiper-pagination swiper-pagination-banner"></div>
      <div class="swiper-button-prev swiper-button-prev-banner"></div><!--左箭头。如果放置在swiper外面，需要自定义样式。-->
      <div class="swiper-button-next swiper-button-next-banner"></div><!--右箭头。如果放置在swiper外面，需要自定义样式。-->
    </div>
    <div class="mian mainHome">
      <div class="wrap">
        <!-- 要闻 -->
        <div class="home_pre clearfloat">
          <div class="home_pre_l fl"><span>要闻头条</span></div>
          <div class="inner">
            <el-carousel height="58px" direction="vertical" :interval="2500" indicator-position="none">
              <el-carousel-item v-for="item in newsTop" :key="item.id">
                <p class="single-line">
                  <a v-if="!item.is_link || item.is_link === 0" class="intro-btn" :href="`${urlName}detail?id=${item.id}&navName=新闻资讯&groupName=公司要闻`">{{ item.title }}</a>
                  <a v-if="item.is_link === 1" target="_blank" class="intro-btn" :href="item.link_url">{{ item.title }}</a>
                </p>
              </el-carousel-item>
            </el-carousel>
          </div>
        </div>

        <!-- 新闻中心 -->
        <div class="homeNews">
          <el-row :gutter="20">
            <el-col :span="11">
              <div class="swiper-container bannerHomeSec01">
                <div class="swiper-wrapper">
                  <div class="swiper-slide" v-for="(item, index) in newsListImg" :key="index">
                    <a v-if="!item.is_link || item.is_link === 0" :href="`${urlName}detail?id=${item.id}&navName=新闻动态&groupName=公司要闻`">
                      <img :src="item.img" alt="">
                      <p class="single-line">{{item.title}}</p>
                    </a>
                  </div>
                </div>
                <div class="swiper-pagination swiper-pagination-sec01"></div>
              </div>

              <!-- <div class="homeNewsWork">
                <el-row :gutter="15">
                  <el-col :span="12">
                    <div class="homeNewsWorkInner">
                      <a href="/listPage">
                        <span class="ico">
                          <img src="../assets/img/ico_zhbg.png" alt="">
                        </span>
                        <span class="txt">
                          公司概况
                        </span>
                      </a>
                    </div>
                  </el-col>
                  <el-col :span="12">
                    <div class="homeNewsWorkInner">
                      <a href="/listPage?t=1">
                        <span class="ico">
                          <img src="../assets/img/ico_zzjg.png" alt="">
                        </span>
                        <span class="txt">
                          组织机构
                        </span>
                      </a>
                    </div>
                  </el-col>
                  <el-col :span="12">
                    <div class="homeNewsWorkInner">
                      <a href="/listPage?t=2">
                        <span class="ico">
                          <img src="../assets/img/ico_rsgl.png" alt="">
                        </span>
                        <span class="txt">
                          领导成员
                        </span>
                      </a>
                    </div>
                  </el-col>
                  <el-col :span="12">
                    <div class="homeNewsWorkInner">
                      <a href="/list?t=2">
                        <span class="ico">
                          <img src="../assets/img/ico_gsgg.png" alt="">
                        </span>
                        <span class="txt">
                          公示公告
                        </span>
                      </a>
                    </div>
                  </el-col>
                </el-row>
              </div> -->
            </el-col>
            <el-col :span="13">
              <div class="homeNewsBox">
                <div class="homeNewsHd homeNewsHdCompany clearfloat">
                  <div class="fl clearfloat">
                    <div class="fl homeNewsTit trans" :class="{'on': newsActive == 0}" @mouseover="newsMouseover(0)">公司要闻</div>
                    <div class="fl homeNewsTit trans" :class="{'on': newsActive == 1}" @mouseover="newsMouseover(1)">基层动态</div>
                    <div class="fl homeNewsTit trans" :class="{'on': newsActive == 2}" @mouseover="newsMouseover(2)">公示公告</div>
                  </div>
                  <div class="fr homeNewsMore">
                    <a :href="`${urlName}list`">MORE</a>
                  </div>
                </div>
                <ul class="news_list news_list02" v-if="newsActive == 0">
                  <li v-for="(item,index) in newsRight01" :key="index" >
                    <div class="news_list_time fr">{{ dayjs(item.create_time).format('MM-DD') }}</div>
                    <p class="news_list_inner single-line">
                      <a v-if="!item.is_link || item.is_link === 0" :href="`${urlName}detail?id=${item.id}&navName=新闻资讯&groupName=公司要闻`">{{item.title}}</a>
                      <a v-if="item.is_link === 1" target="_blank" :href="item.link_url">{{item.title}}</a>
                    </p>
                  </li>
                </ul>
                <ul class="news_list news_list02" v-if="newsActive == 1">
                  <li v-for="(item,index) in newsRight02" :key="index" >
                    <div class="news_list_time fr">{{ dayjs(item.create_time).format('MM-DD') }}</div>
                    <p class="news_list_inner single-line">
                      <a v-if="!item.is_link || item.is_link === 0" :href="`${urlName}detail?id=${item.id}&navName=新闻资讯&groupName=公司要闻`">{{item.title}}</a>
                      <a v-if="item.is_link === 1" target="_blank" :href="item.link_url">{{item.title}}</a>
                    </p>
                  </li>
                </ul>
                <ul class="news_list news_list02" v-if="newsActive == 2">
                  <li v-for="(item,index) in newsRight03" :key="index" >
                    <div class="news_list_time fr">{{ dayjs(item.create_time).format('MM-DD') }}</div>
                    <p class="news_list_inner single-line">
                      <a v-if="!item.is_link || item.is_link === 0" :href="`${urlName}detail?id=${item.id}&navName=新闻资讯&groupName=公司要闻`">{{item.title}}</a>
                      <a v-if="item.is_link === 1" target="_blank" :href="item.link_url">{{item.title}}</a>
                    </p>
                  </li>
                </ul>
              </div>
            </el-col>
          </el-row>
        </div>

        <!-- 业务领域 -->
        <div class="homeBusiness">
          <!-- <div class="homeHd">
            <span class="line"></span><span class="txt">业务领域</span><span class="line"></span>
          </div> -->
          <div class="homeBusinessBox">
            <el-row :gutter="25">
              <el-col :span="12">
                <div class="homeNewsHd clearfloat">
                  <span>党建工作</span>
                  <div class="fr homeNewsMore">
                    <a :href="`${urlName}party`">MORE</a>
                  </div>
                </div>
                <ul class="news_list news_list02">
                  <li v-for="(item,index) in newsParty" :key="index" >
                    <div class="news_list_time fr">{{ dayjs(item.create_time).format('MM-DD') }}</div>
                    <p class="news_list_inner single-line">
                      <a v-if="!item.is_link || item.is_link === 0" :href="`${urlName}detail?id=${item.id}&navName=新闻资讯&groupName=公司要闻`">{{item.title}}</a>
                      <a v-if="item.is_link === 1" target="_blank" :href="item.link_url">{{item.title}}</a>
                    </p>
                  </li>
                </ul>
              </el-col>
              <el-col :span="12">
                <div class="homeNewsHd clearfloat">
                  <span>纪检监察</span>
                  <div class="fr homeNewsMore">
                    <a :href="`${urlName}party?t=1`">MORE</a>
                  </div>
                </div>
                <ul class="news_list news_list02">
                  <li v-for="(item,index) in newsCheck" :key="index" >
                    <div class="news_list_time fr">{{ dayjs(item.create_time).format('MM-DD') }}</div>
                    <p class="news_list_inner single-line">
                      <a v-if="!item.is_link || item.is_link === 0" :href="`${urlName}detail?id=${item.id}&navName=新闻资讯&groupName=公司要闻`">{{item.title}}</a>
                      <a v-if="item.is_link === 1" target="_blank" :href="item.link_url">{{item.title}}</a>
                    </p>
                  </li>
                </ul>
              </el-col>
            </el-row>
          </div>
        </div>

        <!-- 党建专题 -->
        <div class="homeParty">
          <div class="homePartyTop">
            <a href="http://ztjy.people.cn/" title="学习贯彻习近平新时代中国特色社会主义思想主题教育" target="_blank">
              <img src="../assets/img/zt_xxxjpjs3.png" alt="">
            </a>
            <a :href="`${urlName}special`" title="“三抓三促”行动进行时">
              <img src="../assets/img/zt_szsc.jpg" alt="">
            </a>
          </div>
          <div class="homePartyWrap">
            <div class="swiper-container bannerHomeParty" id="bannerHomeParty">
              <div class="swiper-wrapper">
                <div class="swiper-slide">
                  <a href="">
                    <img class="img_c1" src="../assets/img/ad01.png" alt="">
                  </a>
                </div>
                <div class="swiper-slide">
                  <a href="">
                    <img class="img_c1" src="../assets/img/ad02.png" alt="">
                  </a>
                </div>
                <div class="swiper-slide">
                  <a href="">
                    <img class="img_c1" src="../assets/img/ad03.png" alt="">
                  </a>
                </div>
                <div class="swiper-slide">
                  <a href="">
                    <img class="img_c1" src="../assets/img/ad04.png" alt="">
                  </a>
                </div>
              </div>
              <div class="swiper-pagination swiper-pagination-party"></div>
              <div class="swiper-button-prev swiper-button-prev-party"></div><!--左箭头。如果放置在swiper外面，需要自定义样式。-->
              <div class="swiper-button-next swiper-button-next-party"></div><!--右箭头。如果放置在swiper外面，需要自定义样式。-->
            </div>
          </div>
        </div>

        <!-- 项目 -->
        <div class="specail">
          <div class="homeNewsHd homeNewsHdCompany clearfloat" style="margin-bottom: 15px;">
            <div class="fl clearfloat">
              <div class="fl homeNewsTit trans" :class="{'on': newsActivePre == 0}" @mouseover="newsMouseoverPre(0)">项目概况</div>
              <div class="fl homeNewsTit trans" :class="{'on': newsActivePre == 1}" @mouseover="newsMouseoverPre(1)">项目掠影</div>
            </div>
            <div class="fr homeNewsMore">
              <a :href="`${urlName}business`">MORE</a>
            </div>
          </div>
          <div class="specailBox" v-if="newsActivePre == 0">
            <div class="swiper-container bannerSpecail">
              <div class="swiper-wrapper">
                <div class="swiper-slide" v-for="(item, index) in specailList01" :key="index">
                  <a v-if="!item.is_link || item.is_link === 0" :href="`${urlName}detail?id=${item.id}&navName=新闻资讯&groupName=公司要闻`">
                    <img class="img_c1" :src="item.img" alt="">
                    <p>{{item.title}}</p>
                  </a>
                  <a v-if="item.is_link === 1" target="_blank" :href="item.link_url">
                    <img class="img_c1" :src="item.img" alt="">
                    <p>{{item.title}}</p>
                  </a>
                </div>
              </div>
            </div>
            <div class="swiper-button-prev swiper-button-prev-specail"></div><!--左箭头。如果放置在swiper外面，需要自定义样式。-->
            <div class="swiper-button-next swiper-button-next-specail"></div><!--右箭头。如果放置在swiper外面，需要自定义样式。-->
          </div>
          <div class="specailBox" v-if="newsActivePre == 1">
            <div class="swiper-container bannerSpecail">
              <div class="swiper-wrapper">
                <div class="swiper-slide" v-for="(item, index) in specailList02" :key="index">
                  <a v-if="!item.is_link || item.is_link === 0" :href="`${urlName}detail?id=${item.id}&navName=新闻资讯&groupName=公司要闻`">
                    <img class="img_c1" :src="item.img" alt="">
                    <p>{{item.title}}</p>
                  </a>
                  <a v-if="item.is_link === 1" target="_blank" :href="item.link_url">
                    <img class="img_c1" :src="item.img" alt="">
                    <p>{{item.title}}</p>
                  </a>
                </div>
              </div>
            </div>
            <div class="swiper-button-prev swiper-button-prev-specail"></div><!--左箭头。如果放置在swiper外面，需要自定义样式。-->
            <div class="swiper-button-next swiper-button-next-specail"></div><!--右箭头。如果放置在swiper外面，需要自定义样式。-->
          </div>
        </div>

      </div>
    </div>
  </div>
</template>
<script>
import Swiper from "swiper"
export default {
  name: 'home',
  data(){
    return {
      newsParty: [],
      newsCheck: [],
      newsListImg: [],
      newsActive: 0,
      newsActivePre: 0,
      listZfgs: [
        {
          name: '集团官网',
          url: 'https://www.ghatg.com/'
        },
        {
          name: '新华网',
          url: ''
        },
        {
          name: '人民网',
          url: ''
        },
        {
          name: '中国网',
          url: ''
        },
        {
          name: '中国网络电视台',
          url: ''
        },
        {
          name: '中国日报网',
          url: ''
        },
        {
          name: '国际在线',
          url: ''
        },
        {
          name: '中青在线',
          url: ''
        },
        {
          name: '中国经济网',
          url: ''
        },
        {
          name: '中国西藏网',
          url: ''
        },
        {
          name: '中国台湾网',
          url: ''
        },
        {
          name: '央广闷',
          url: ''
        },
        {
          name: '光明网',
          url: ''
        },
        {
          name: '中国新闻网',
          url: ''
        },
        {
          name: '学习强国',
          url: ''
        },
        {
          name: '中央纪委国家监察委网站',
          url: ''
        },
        {
          name: '党建网',
          url: ''
        },
        {
          name: '思想政治工作',
          url: ''
        },
        {
          name: '全国宣传干部学院',
          url: ''
        },
        {
          name: '形势政策网',
          url: ''
        },
        {
          name: '学习出版杜',
          url: ''
        },
        {
          name: '全国社科规划网',
          url: ''
        },
        {
          name: '每日甘肃网',
          url: ''
        },
        {
          name: '中国甘肃网',
          url: ''
        },
        {
          name: '中国兰州网',
          url: ''
        }
      ],
      newsTop: [],
      newsLeft: [],
      newsRight: [
        {
          title: '集团公司领导赴焉支山景区调研指导工作',
          img: require('../assets/img/home_vedio_img01.jpg'),
          id: '1',
          cont: '9月15日，集团公司副总经理孙进玲一行赴焉支山景区调研指导工作，旅投集团负责人陪同调研旅投集团负责人陪同调研旅投集团负责人陪同调研。  孙进玲副总经理实地查看了焉支山景区',
          creat_time: '2021-05-14'
        },
        {
          title: '集团公司领导赴焉支山景区调研指导工作',
          img: require('../assets/img/home_vedio_img01.jpg'),
          id: '1',
          cont: '9月15日，集团公司副总经理孙进玲一行赴焉支山景区调研指导工作，旅投集团负责人陪同调研。  孙进玲副总经理实地查看了焉支山景区',
          creat_time: '2021-05-14'
        },
        {
          title: '集团公司领导赴焉支山景区调研指导工作',
          img: require('../assets/img/home_vedio_img01.jpg'),
          id: '1',
          cont: '9月15日，集团公司副总经理孙进玲一行赴焉支山景区调研指导工作，旅投集团负责人陪同调研。  孙进玲副总经理实地查看了焉支山景区',
          creat_time: '2021-05-14'
        },
        {
          title: '集团公司领导赴焉支山景区调研指导工作',
          img: require('../assets/img/home_vedio_img01.jpg'),
          id: '1',
          cont: '9月15日，集团公司副总经理孙进玲一行赴焉支山景区调研指导工作，旅投集团负责人陪同调研旅投集团负责人陪同调研旅投集团负责人陪同调研。  孙进玲副总经理实地查看了焉支山景区',
          creat_time: '2021-05-14'
        },
        {
          title: '集团公司领导赴焉支山景区调研指导工作',
          img: require('../assets/img/home_vedio_img01.jpg'),
          id: '1',
          cont: '9月15日，集团公司副总经理孙进玲一行赴焉支山景区调研指导工作，旅投集团负责人陪同调研。  孙进玲副总经理实地查看了焉支山景区',
          creat_time: '2021-05-14'
        },
        {
          title: '集团公司领导赴焉支山景区调研指导工作',
          img: require('../assets/img/home_vedio_img01.jpg'),
          id: '1',
          cont: '9月15日，集团公司副总经理孙进玲一行赴焉支山景区调研指导工作，旅投集团负责人陪同调研。  孙进玲副总经理实地查看了焉支山景区',
          creat_time: '2021-05-14'
        },
        {
          title: '集团公司领导赴焉支山景区调研指导工作',
          img: require('../assets/img/home_vedio_img01.jpg'),
          id: '1',
          cont: '9月15日，集团公司副总经理孙进玲一行赴焉支山景区调研指导工作，旅投集团负责人陪同调研。  孙进玲副总经理实地查看了焉支山景区',
          creat_time: '2021-05-14'
        },
        {
          title: '集团公司领导赴焉支山景区调研指导工作',
          img: require('../assets/img/home_vedio_img01.jpg'),
          id: '1',
          cont: '9月15日，集团公司副总经理孙进玲一行赴焉支山景区调研指导工作，旅投集团负责人陪同调研。  孙进玲副总经理实地查看了焉支山景区',
          creat_time: '2021-05-14'
        },
        {
          title: '集团公司领导赴焉支山景区调研指导工作',
          img: require('../assets/img/home_vedio_img01.jpg'),
          id: '1',
          cont: '9月15日，集团公司副总经理孙进玲一行赴焉支山景区调研指导工作，旅投集团负责人陪同调研。  孙进玲副总经理实地查看了焉支山景区',
          creat_time: '2021-05-14'
        },
        {
          title: '集团公司领导赴焉支山景区调研指导工作',
          img: require('../assets/img/home_vedio_img01.jpg'),
          id: '1',
          cont: '9月15日，集团公司副总经理孙进玲一行赴焉支山景区调研指导工作，旅投集团负责人陪同调研。  孙进玲副总经理实地查看了焉支山景区',
          creat_time: '2021-05-14'
        },
      ],
      newsRight01: [],
      newsRight02: [],
      newsRight03: [],
      options01: [
        {
          name: '外交部',
          url: 'http://www.fmprc.gov.cn'
        },
        {
          name: '国防部',
          url: 'http://www.mod.gov.cn/'
        },
        {
          name: '发展改革委',
          url: 'http://www.ndrc.gov.cn/'
        },
        {
          name: '教育部',
          url: 'http://www.moe.edu.cn/'
        },
        {
          name: '科技部',
          url: 'http://www.most.gov.cn/'
        },
        {
          name: '中纪委',
          url: 'https://www.ccdi.gov.cn/'
        },
        {
          name: '工业和信息化部',
          url: 'http://www.miit.gov.cn/'
        },
        {
          name: '自然资源部',
          url: 'http://www.mnr.gov.cn/'
        },
        {
          name: '公安部',
          url: 'http://www.mps.gov.cn/'
        },
        {
          name: '民政部',
          url: 'http://www.mca.gov.cn'
        },
        {
          name: '司法部',
          url: 'http://www.moj.gov.cn'
        },
        {
          name: '财政部',
          url: 'http://www.mof.gov.cn'
        },
        {
          name: '人力资源社会保障部',
          url: 'http://www.mohrss.gov.cn'
        },
        {
          name: '生态环境部',
          url: 'http://www.mee.gov.cn'
        },
        {
          name: '住房城乡建设部',
          url: 'http://www.mohurd.gov.cn'
        },
        {
          name: '交通运输部',
          url: 'http://www.mot.gov.cn'
        },
        {
          name: '水利部',
          url: 'http://www.mwr.gov.cn/'
        },
        {
          name: '农业农村部',
          url: 'http://www.moa.gov.cn/'
        },
        {
          name: '商务部',
          url: 'http://www.mofcom.gov.cn'
        },
        {
          name: '文化旅游部',
          url: 'http://www.mct.gov.cn/'
        },
        {
          name: '国家卫生健康委员会',
          url: 'http://www.nhc.gov.cn'
        },
        {
          name: '应急管理部',
          url: 'http://www.chinasafety.gov.cn/'
        },
        {
          name: '人民银行',
          url: 'http://www.pbc.gov.cn'
        },
        {
          name: '审计署',
          url: 'http://www.audit.gov.cn/'
        },
        {
          name: '国资委',
          url: 'http://www.sasac.gov.cn/index.html'
        },
        {
          name: '海关总署',
          url: 'http://www.customs.gov.cn/publish/portal0/'
        },
        {
          name: '税务总局',
          url: 'http://www.chinatax.gov.cn/'
        },
        {
          name: '国家市场监督管理总局',
          url: 'http://www.aqsiq.gov.cn/'
        },
        {
          name: '国家广播电视总局',
          url: 'http://www.sapprft.gov.cn/'
        },
        {
          name: '体育总局',
          url: 'http://www.sport.gov.cn/'
        },
        {
          name: '统计局',
          url: 'http://www.stats.gov.cn/'
        },
        {
          name: '知识产权局',
          url: 'http://www.sipo.gov.cn/'
        },
        {
          name: '宗教局',
          url: 'http://www.sara.gov.cn/'
        },
        {
          name: '参事室',
          url: 'http://www.counsellor.gov.cn/'
        },
        {
          name: '国管局',
          url: 'http://www.ggj.gov.cn/'
        },
        {
          name: '版权局',
          url: 'http://www.ncac.gov.cn/'
        },
        {
          name: '法制办',
          url: 'http://www.chinalaw.gov.cn/'
        },
        {
          name: '国务院研究室',
          url: 'http://www.gov.cn/gjjg/2005-12/26/content_137261.htm'
        },
        {
          name: '台办',
          url: 'http://www.gwytb.gov.cn/'
        },
        {
          name: '新闻办',
          url: 'http://www.scio.gov.cn/'
        },
        {
          name: '新华社',
          url: 'http://www3.xinhuanet.com/'
        },
        {
          name: '中科院',
          url: 'http://www.cas.cn/'
        },
        {
          name: '社科院',
          url: 'http://cass.cssn.cn/'
        },
        {
          name: '工程院',
          url: 'http://www.cae.cn/'
        },
        {
          name: '发展研究中心',
          url: 'http://www.drc.gov.cn/'
        },
        {
          name: '中央党校',
          url: 'http://www.nsa.gov.cn/'
        },
        {
          name: '气象局',
          url: 'http://www.cma.gov.cn/'
        },
        {
          name: '地震局',
          url: 'http://www.cea.gov.cn/'
        },
        {
          name: '证监会',
          url: 'http://www.csrc.gov.cn/pub/newsite/'
        },
      ],
      options02: [
        {
          name: '省国资委',
          url: 'http://gzw.gansu.gov.cn/'
        },
        {
          name: '省发展改革委',
          url: 'http://fzgg.gansu.gov.cn/'
        },
        {
          name: '省教育厅',
          url: 'http://jyt.gansu.gov.cn/'
        },
        {
          name: '省科技厅',
          url: 'http://kjt.gansu.gov.cn/'
        },
        {
          name: '省工信厅',
          url: 'http://gxt.gansu.gov.cn/'
        },
        {
          name: '省政府国资委',
          url: 'http://gzw.gansu.gov.cn/'
        },
        {
          name: '省纪委',
          url: 'http://www.gsjw.gov.cn/'
        },
        {
          name: '省民族事务委员会',
          url: 'http://mzsw.gansu.gov.cn/'
        },
        {
          name: '省公安厅',
          url: 'http://gat.gansu.gov.cn/'
        },
        {
          name: '省民政厅',
          url: 'http://mzt.gansu.gov.cn/'
        },
        {
          name: '省司法厅',
          url: 'http://sft.gansu.gov.cn/'
        },
        {
          name: '省财政厅',
          url: 'http://czt.gansu.gov.cn/'
        },
        {
          name: '省人社厅',
          url: 'http://rst.gansu.gov.cn/'
        },
        {
          name: '省自然资源厅',
          url: 'http://zrzy.gansu.gov.cn/'
        },
        {
          name: '省生态环境厅',
          url: 'http://sthj.gansu.gov.cn/'
        },
        {
          name: '省住建厅',
          url: 'http://zjt.gansu.gov.cn/'
        },
        {
          name: '省交通运输厅',
          url: 'http://jtys.gansu.gov.cn/'
        },
        {
          name: '省水利厅',
          url: 'http://slt.gansu.gov.cn/'
        },
        {
          name: '省农业农村厅',
          url: 'http://nync.gansu.gov.cn/'
        },
        {
          name: '省商务厅',
          url: 'http://swt.gansu.gov.cn/'
        },
        {
          name: '省文旅厅',
          url: 'http://wlt.gansu.gov.cn/'
        },
        {
          name: '省卫生健康委',
          url: 'http://wsjk.gansu.gov.cn/'
        },
        {
          name: '省退役军人厅',
          url: 'http://tyjr.gansu.gov.cn/'
        },
        {
          name: '省应急厅',
          url: 'http://yjgl.gansu.gov.cn/'
        },
        {
          name: '省审计厅',
          url: 'http://sjt.gansu.gov.cn/'
        },
        {
          name: '省政府外事办',
          url: 'http://wsb.gansu.gov.cn/'
        },
      ],
      options03: [
        {
          name: '金川集团有限公司',
          url: 'http://www.jnmc.com/'
        },
        {
          name: '酒泉钢铁集团',
          url: 'http://www.jiugang.com/'
        },
        {
          name: '白银有色集团股份有限公司',
          url: 'http://www.bynmc.com/'
        },
        {
          name: '甘肃建投',
          url: 'http://www.gsjtw.cc/'
        },
        {
          name: '甘肃省公路航空旅游投资集团有限公司',
          url: 'http://www.ghatg.com/'
        }
      ],
      options05: [
        {
          name: '国家开发银行',
          url: 'http://www.cdb.com.cn'
        },
        {
          name: '中国进出口银行',
          url: 'http://www.eximbank.gov.cn'
        },
        {
          name: '中国农业发展银行',
          url: 'http://www.adbc.com.cn'
        },
        {
          name: '中国工商银行',
          url: 'http://www.icbc.com.cn'
        },
        {
          name: '中国农业银行',
          url: 'http://www.abchina.com'
        },
        {
          name: '中国银行',
          url: 'http://www.bank-of-china.com'
        },
        {
          name: '中国建设银行',
          url: 'http://www.ccb.com/cn/home/indexv3.html'
        },
        {
          name: '交通银行',
          url: 'http://www.bankcomm.com'
        },
        {
          name: '招商银行',
          url: 'http://www.cmbchina.com'
        },
        {
          name: '信托网',
          url: 'http://www.trust-one.com'
        }
      ],
      value01: '国家部委网站',
      value02: '省直部门网站',
      value03: '省属国企网站',
      value04: '国内同行网站',
      value05: '金融机构网站',
      specailList01: [],
      specailList02: []
    }
  },
  mounted(){
    this.bannerInit()
    // this.bannerHomeSec01()
    // this.bannerSpecail()
    this.bannerParty()

    this.getImprotNews()
    this.getImgNews()
    this.companyNews()
    this.companyNewsProject()
    this.companyNewsNotice()
    this.getParty()
    this.getCheck()
    this.getBusinessData01()
    this.getBusinessData02()
  },
  methods: {
    // 新闻头条
    async getImprotNews(){
      var _this = this;
      var { data } = await _this.$http.post(_this.apiUrl + '/news/getImportNews', {company_id: _this.companyId});
      _this.newsTop = data.data.list.slice(0,4);
    },
    // 焦点图
    async getImgNews(){
      var _this = this;
      var { data } = await _this.$http.post(_this.apiUrl + '/news/getImgNews', {company_id: _this.companyId});
      _this.newsListImg = data.data.list.slice(0,6);
      this.$nextTick(() => {
        _this.bannerHomeSec01()
      })
    },
    // 公司新闻
    async companyNews() {
      var _this = this;
      var { data } = await _this.$http.post(_this.apiUrl + '/news/getNewsList', {group_id: "442", page: 1, pageSize: 10});
      _this.newsRight01 = data.data.list;
    },
    async companyNewsProject() {
      var _this = this;
      var { data } = await _this.$http.post(_this.apiUrl + '/news/getNewsList', {group_id: "443", page: 1, pageSize: 10});
      _this.newsRight02 = data.data.list;
    },
    async companyNewsNotice() {
      var _this = this;
      var { data } = await _this.$http.post(_this.apiUrl + '/news/getNewsList', {group_id: "459", page: 1, pageSize: 10});
      _this.newsRight03 = data.data.list;
    },
    // 党建工作
    async getParty() {
      var _this = this;
      var { data } = await _this.$http.post(_this.apiUrl + '/news/getNewsList', {group_id: "448", page: 1, pageSize: 6});
      _this.newsParty = data.data.list;
    },
    // 纪检监察
    async getCheck() {
      var _this = this;
      var { data } = await _this.$http.post(_this.apiUrl + '/news/getNewsList', {group_id: "449", page: 1, pageSize: 6});
      _this.newsCheck = data.data.list;
    },
    // 项目
    async getBusinessData01() {
      var _this = this;
      var { data } = await _this.$http.post(_this.apiUrl + '/news/getNewsListImg', {group_id: "446", page: 1, pageSize: 20});
      _this.specailList01 = data.data.list;
      this.bannerSpecail()
    },
    // 项目
    async getBusinessData02() {
      var _this = this;
      var { data } = await _this.$http.post(_this.apiUrl + '/news/getNewsListImg', {group_id: "447", page: 1, pageSize: 20});
      _this.specailList02 = data.data.list;
      this.bannerSpecail()
    },
    // banner
    bannerInit(){
      new Swiper(".bannerHome", {
        autoplay: {
          disableOnInteraction: false,
        },
        loop: true,
        pagination: {
          el: ".swiper-pagination-banner",
          type: 'fraction',
        },
        navigation: {
          nextEl: '.swiper-button-next-banner',
          prevEl: '.swiper-button-prev-banner',
          hideOnClick: true,
        },
        observer: true, //修改swiper自己或子元素时，自动初始化swiper
        observeParents: true, //修改swiper的父元素时，自动初始化swiper
        autoplayDisableOnInteraction: false,   //用户操作swiper之后重新启动autoplay
      })
    },
    // 焦点图
    bannerHomeSec01(){
      new Swiper(".bannerHomeSec01", {
        autoplay: {
          disableOnInteraction: false,
        },
        loop: true,
        pagination: {
          el: ".swiper-pagination-sec01",
          clickable :true,
        },
        observer: true, //修改swiper自己或子元素时，自动初始化swiper
        observeParents: true, //修改swiper的父元素时，自动初始化swiper
        autoplayDisableOnInteraction: false,   //用户操作swiper之后重新启动autoplay
      })
    },
    // 专题专栏
    bannerSpecail(){
      new Swiper(".bannerSpecail", {
        autoplay: {
          disableOnInteraction: false,
        },
        loop: true,
        slidesPerView : 4,
        spaceBetween : 20,
        navigation: {
          nextEl: '.swiper-button-next-specail',
          prevEl: '.swiper-button-prev-specail',
          hideOnClick: true,
        },
        observer: true, //修改swiper自己或子元素时，自动初始化swiper
        observeParents: true, //修改swiper的父元素时，自动初始化swiper
        autoplayDisableOnInteraction: false,   //用户操作swiper之后重新启动autoplay
      })
    },
    // 党建专栏
    bannerParty(){
      new Swiper(".bannerHomeParty", {
        autoplay: {
          disableOnInteraction: false,
        },
        loop: true,
        pagination: {
          el: ".swiper-pagination-party",
          // type: 'fraction',
        },
        navigation: {
          nextEl: '.swiper-button-next-party',
          prevEl: '.swiper-button-prev-party',
          hideOnClick: true,
        },
        observer: true, //修改swiper自己或子元素时，自动初始化swiper
        observeParents: true, //修改swiper的父元素时，自动初始化swiper
        autoplayDisableOnInteraction: false,   //用户操作swiper之后重新启动autoplay
      })
    },
    newsMouseover(val) {
      var _this = this

      _this.newsActive = val
    },
    newsMouseoverPre(val) {
      var _this = this

      _this.newsActivePre = val
    }
  }
}
</script>
<style scoped>
.bannerHome {
  height: 560px;
  position: relative;
}
.bannerHome .swiper-wrapper,
.bannerHome .swiper-slide,
.bannerHome img {
  height: 100%;
}
.bannerHome img {
  width: 100%;
}
.swiper-pagination-banner {
  color: #fff;
  font-size: 16px;
}
.swiper-pagination-current {
  font-size: 20px;
}
.secBox01 {
  padding: 60px 0;
  background-image: url(../assets/img/home_bg_sec01.png);
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
}
.secBox01Hd {
  margin-bottom: 30px;
  line-height: 48px;
}
.secBox01Title {
  font-size: 40px;
  color: #8A8A8A;
  text-transform: Uppercase;
}
.secBox01Txt {
  font-size: 30px;
  color: #535353;
}
.secBox01Tab {
  margin-bottom: 54px;
  text-align: center;
}
.secBox01Tab span {
  display: inline-block;
  height: 30px;
  line-height: 30px;
  padding: 0 18px;
  margin: 0 26px;
  font-size: 16px;
  color: #282828;
  background-color: #fff;
  cursor: pointer;
  border-radius: 20px;
}
.secBox01Tab span.active {
  color: #fff;
  background-color: #BC8F68;
}
.bannerHomeSec01 {
  height: 376px;
  position: relative;
}
.bannerHomeSec01 .swiper-wrapper,
.bannerHomeSec01 .swiper-slide,
.bannerHomeSec01 img {
  height: 100%;
}
.bannerHomeSec01 img {
  width: 100%;
  height: 100%;
}
.bannerHomeSec01 .swiper-slide {
  position: relative;
}
.bannerHomeSec01 p {
  position: absolute;
  width: 100%;
  height: 40px;
  line-height: 40px;
  padding: 0 118px 0 12px;
  left: 0;
  bottom: 0;
  color: #fff;
  font-size: 16px;
  background-color: rgba(0,0,0,0.8);
  box-sizing: border-box;
  text-align: left;
}
.bannerHomeSec01 .swiper-pagination-bullet {
  width: 10px;
  height: 10px;
  border-radius: 0;
  background-color:rgba(255, 255, 255, 0.8);
  opacity: 1;
}
.bannerHomeSec01 .swiper-pagination-bullet-active {
  background-color:#CE251F;
}
.bannerHomeSec01 .swiper-pagination {
  text-align: right;
  left: auto;
  right: 8px;
}
/* 友情链接 */
.sec_zfgs_list a {
    display: block;
    text-align: center;
    line-height: 34px;
    border: 1px solid #d5d5d5;
    margin-bottom: 6px;
}

.sec_zfgs_list a:hover {
    border-color: #2b78c6;
    color: #fff;
    background-color: #2b78c6;
}
.homePartyTop {
  margin-bottom: 15px;
}
.homePartyTop a {
  display: block;
  margin-bottom: 15px;
}
.homePartyTop img {
  width: 100%;
  height: 100px;
}
</style>